import { useRouter } from 'next/router';
import { formatDateByLocale } from '@pafcloud/locale';
import type { DateLike } from '@pafcloud/locale';
import { useIsSSR } from './useIsSSR';

export function useFormatDate() {
  const router = useRouter();
  const isSSR = useIsSSR();

  return function formatDate(date: DateLike, options?: Intl.DateTimeFormatOptions) {
    // If dates differ between server and client,
    // we may get a mismatch when hydrating.
    if (isSSR) {
      return '';
    }

    if (options?.month === 'long') {
      return formatDateByLocale(date, router.locale, {
        ...options,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }

    return formatDateByLocale(date, router.locale, options);
  };
}
